<template>
  <div class="password" :class="[bars]">
    <div class="levels" v-for="bar in 4" :key="bar"></div>
    <p v-if="complexity.amount && complexity.case && complexity.number && complexity.special">Password is Good!</p>
      <!-- && complexity.focus === 1 -->
    <transition name="fade">
      <div class="instructions" v-if="(!complexity.amount || !complexity.case || !complexity.number || !complexity.special) && complexity.focus === 1"
      :class="[route.matched.some(({ name }) => name === 'reset-password') ? 'reset_pass' : null]">
        <p v-if="!complexity.amount">Less than 8 characters</p>
        <p v-if="!complexity.case">Must have a case change</p>
        <p v-if="!complexity.number">Must have a number</p>
        <p v-if="!complexity.special">Must have a special character</p>
      </div>
    </transition>

  </div>
</template>

<script setup>
import { defineProps, ref, watch } from 'vue';
import {useRoute, useRouter} from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps({ complexity: Object });
let trues = ref(0);
let bars = ref('');

watch(() => props.complexity, (newValue) => {
  trues.value = Object.values(newValue).reduce((a, item) => a + (item === true ? 1 : 0), 0)
  if(trues.value === 0) {
    bars.value = ''
  } else if (trues.value === 1){
    bars.value = 'one'
  } else if (trues.value === 2){
    bars.value = 'two'
  } else if (trues.value === 3){
    bars.value = 'three'
  } else if (trues.value === 4){
    bars.value = 'four'
  }
}, {
  immediate: true,
  deep: true,
});
</script>

<style lang="scss" scoped>
.password{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 10px;
  margin-top: 10px;
  position: relative;
  .levels{
    width: calc(25% - 8px);
    height: 10px;
    border-radius: 5px;
    background-color: #2525251A;
  }
  >p{
    width: 100%;
    flex-basis: 100%;
    margin: 0 0 16px 0;
    text-align: right;
    font-size: 0.82rem;
    color: #787878;
  }
}
.instructions{
  width: 100%;
  background-color: rgba(256, 256, 256, .4);
  top: 20px;
  padding: 8px;
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  text-align: right;
  backdrop-filter: blur(10px);
  border: 1px solid #F44336;
  background-image: url(../../../public/img/alert.svg);
  background-repeat: no-repeat;
  background-position: left 10px center;
  p{
    margin: 0px;
  }
}
.instructions.reset_pass{
  position:relative;
  top: 10px;
}
.one{
  .levels:first-child{
    background-color: #006C3E;
  }
}
.two{
  .levels:first-child,>.levels:nth-child(2){
    background-color: #006C3E;
  }
}
.three{
  .levels:first-child,>.levels:nth-child(2),>.levels:nth-child(3){
    background-color: #006C3E;
  }
}
.four{
  >.levels{
    background-color: #006C3E;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>